import { Header } from '@maersktankersdigital/web-components'
import { ExactScopeName, useHasScope } from '~hooks/permissions/use-has-scope'
import { useConstructLinkWithPool } from '~hooks/use-construct-link-with-pool'

export function useSubheaderLinks() {
  const calendarLink = useConstructLinkWithPool('/panel/calendar/:pool')
  const newsLink = useConstructLinkWithPool('/panel/news/:pool')
  const isTechnicalManager = useHasScope(ExactScopeName.technicalManager)
  const isMtUser = useHasScope(ExactScopeName.maerskTankersUser)
  const isPoolPartner = useHasScope(ExactScopeName.poolPartner)

  const subheaderPages: Header[] = [
    ...(isTechnicalManager
      ? []
      : [
          {
            title: 'news',
            link: newsLink,
          },
          {
            title: 'calendar',
            link: calendarLink,
          },
        ]),
    {
      title: 'help',
      link: '/panel/help',
    },
    {
      title: 'contact',
      link: 'panel/contact',
    },
    {
      title: 'Profile',
      link: '/profile',
    },
  ]

  if (isPoolPartner || isTechnicalManager || isMtUser) {
    return subheaderPages
  }

  return [
    {
      title: 'Profile',
      link: '/profile',
    },
  ]
}
